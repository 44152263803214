import dayjs from 'dayjs'
import { DATE_FORMAT } from '@/utills/constant'

export const VM_FILTERS = {
  subscription: '',
  resource_group: '',
  virtual_machine: '',
  table_name: '',
  start_date: dayjs().subtract(1, 'month').startOf('month').format(DATE_FORMAT),
  end_date: dayjs().subtract(1, 'month').endOf('month').format(DATE_FORMAT),
}