'use client';
import React, { useEffect, useState } from 'react';
import { getSession, signOut, SessionProvider } from 'next-auth/react';
import axiosFrontend from '@/utills/axiosFrontend';
import Layout from '../components/layouts/layout';
import LayoutLogin from '../components/layouts/layoutLogin';

export default function AuthProvider({ children }: { children: React.ReactNode }) {
    const [auth, setAuth] = useState<string | boolean | undefined>();

    useEffect(() => {
        async function setAuthenticate() {
            const session = await getSession();
    
            if (session && session.user?.accessToken) {
                const expiresAt = new Date(session.expires).getTime(); // Convert to timestamp
                const currentTime = Date.now();
                const timeLeft = expiresAt - currentTime; // Time left in milliseconds
    
                console.log(`Access token expires at: ${new Date(expiresAt).toLocaleString()}`);
                
                // Set Authorization Header
                axiosFrontend.defaults.headers.common['Authorization'] = `Bearer ${session.user.accessToken}`;
                setAuth(session.user.accessToken);
    
                // Logout user automatically when token expires
                if (timeLeft > 0) {
                    const interval = setInterval(() => {
                        const now = Date.now();
                        const remainingTime = expiresAt - now;
                        const minutesLeft = Math.floor(remainingTime / (1000 * 60)); // Convert to minutes
                        const secondsLeft = Math.floor((remainingTime % (1000 * 60)) / 1000); // Remaining seconds
    
                        console.log(`Time left before logout: ${minutesLeft} minutes ${secondsLeft} seconds`);
    
                        if (remainingTime <= 0) {
                            console.warn("Access token expired. Logging out...");
                            clearInterval(interval);
                            signOut({ callbackUrl: "/login" });
                        }
                    }, 30 * 1000); // Run every 30 seconds
    
                    return () => clearInterval(interval); // Cleanup on unmount
                } else {
                    console.warn("Access token already expired. Logging out...");
                    signOut({ callbackUrl: "/login" });
                }
            } else {
                setAuth(false);
            }
        }
    
        setAuthenticate();
    }, []);
    
    

    if (auth) {
        return (
            <SessionProvider>
                <Layout>{children}</Layout>
            </SessionProvider>
        );
    }

    if (auth === false) {
        return (
            <SessionProvider>
                <LayoutLogin>{children}</LayoutLogin>
            </SessionProvider>
        );
    }

    return <div className='page-loading'>Loading...</div>;
}
