import dayjs from 'dayjs'
import { DATE_FORMAT } from '@/utills/constant'

export const EC2_FILTERS = {
  account_name: '',
  region: '',
  resource_name: '',
  start_date: dayjs().subtract(1, 'month').startOf('month').format(DATE_FORMAT),
  end_date: dayjs().subtract(1, 'month').endOf('month').format(DATE_FORMAT),
}

console.log(EC2_FILTERS, "ec2 filters in constant")


